/* Base Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f3f4f6;
  }
  
  /* Services Section */
  .services_section {
    padding: 60px 0;
    background: linear-gradient(135deg, #ffffff, #e9e9e9);
  }
  
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .heading_container {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .heading_container h2 {
    font-size: 36px;
    color: #2c3e50; /* Darker shade for better contrast */
  }
  
  .heading_container p {
    font-size: 18px;
    color: #34495e; /* Slightly lighter shade for better readability */
  }
  
  /* Services Content */
  .services_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .service {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(33.333% - 20px);
    margin: 10px;
    text-align: center;
  }
  
  .service img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .service h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #2980b9; /* Blue color for headings */
  }
  
  .service p {
    font-size: 16px;
    color: #7f8c8d; /* Soft gray for paragraph text */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .services_content {
      flex-direction: column;
      align-items: center;
    }
  
    .service {
      width: 90%;
    }
  }
  