/* Base Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f3f4f6;
  }
  
  /* Programs Section */
  .programs_section {
    padding: 60px 0;
    background: linear-gradient(135deg, #ffffff, #e9e9e9);
  }
  
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .heading_container {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .heading_container h2 {
    font-size: 36px;
    color: #333;
  }
  
  .heading_container p {
    font-size: 18px;
    color: #666;
  }
  
  /* Programs Content */
  .programs_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .program {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(50% - 20px);
    margin: 10px;
    text-align: center;
  }
  
  .program img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .program h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .program p {
    font-size: 16px;
    color: #666;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .program {
      width: 90%;
    }
  }
  