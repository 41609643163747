/* General Styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    height: 100vh;
}

/* Full Background Image */
body {
    background: url('./images/240_F_303520596_RPlRH6ZXN0vrUwhiAuerT4puFjqOlFq3.jpg') no-repeat center center fixed; 
    background-size: cover;
    position: relative;
    z-index: 1;
}

/* Dark overlay effect */
body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
}

/* Centering container */
.container, .container-wrap {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column; /* Stack all content vertically */
}

/* Section padding */
.ftco-section {
    padding: 80px 0;
}

/* Heading styles */
.heading-section {
    text-align: center;
    margin-bottom: 50px;
    color: #fff;
    animation: fadeInDown 1.5s ease-in-out;
}

.heading-section h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
}

.heading-section .subheading {
    font-size: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 30px;
}

/* Request Video Section (Transparent Background) */
.request-video {
    background: rgba(255, 255, 255, 0.2); /* Semi-transparent white background */
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px); /* Adds a blur effect for better readability */
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; /* Stack the content vertically */
    text-align: center;
}

/* Video Container */
.video-container {
    max-width: 400px;
    width: 100%;
    margin: 50px 0;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%; /* Increased height */
    border-radius: 10px;
}

/* Button styling for Request Video Section */
.request-video .btn {
    background: #ff5722;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 10px;
    display: inline-block;
}

.request-video .btn:hover {
    background-color: #e64a19;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .request-video {
        padding: 30px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 20px;
    }

    .video-container iframe {
        height: 250px; /* Adjusted height for smaller screens */
    }

    .heading-section h2 {
        font-size: 28px;
    }

    .heading-section .subheading {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .heading-section h2 {
        font-size: 24px;
    }

    .request-video {
        padding: 20px;
    }

    .video-container iframe {
        height: 200px; /* Further reduce video height on small screens */
    }

    .request-video .btn {
        margin-top: 5px;
    }
}

/* Keyframes for animations */
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
