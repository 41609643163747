/* Slider Section Styling */
.slider_section {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  text-align: center;
}

/* Styling for Title */
.item_title {
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #f0f0f0;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

/* Styling for Description */
.item_description {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #ccc;
  line-height: 1.6;
}

/* Styling for Subtitle */
.item_subtitle {
  font-size: 1.2em;
  font-style: italic;
  margin-bottom: 20px;
  color: #a9a9a9;
  letter-spacing: 0.5px;
}

/* Button 1 (Read More) Styling */
.btn-1 {
  display: inline-block;
  margin: 15px 10px;
  padding: 12px 24px;
  color: #fff;
  background-color: #007bff; /* Blue color for Read More button */
  border: none;
  border-radius: 30px;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 123, 255, 0.3);
}

.btn-1:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05);
  box-shadow: 0px 6px 10px rgba(0, 123, 255, 0.5);
}

/* Button 2 (Contact Us) Styling */
.btn-2 {
  display: inline-block;
  margin: 15px 10px;
  padding: 12px 24px;
  color: #fff;
  background-color: #28a745; /* Green color for Contact Us button */
  border: none;
  border-radius: 30px;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 6px rgba(40, 167, 69, 0.3);
}

.btn-2:hover {
  background-color: #218838; /* Darker green on hover */
  transform: scale(1.05);
  box-shadow: 0px 6px 10px rgba(40, 167, 69, 0.5);
}

/* General Slider Button Styling */
.slider_button {
  display: inline-block;
  margin: 10px 5px;
  padding: 12px 24px;
  color: #fff;
  border: 2px solid #fff;
  background-color: transparent;
  border-radius: 25px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.slider_button:hover {
  background-color: #fff;
  color: #000;
  transform: scale(1.05);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .item_title {
    font-size: 2.5em;
  }

  .item_description {
    font-size: 1.3em;
  }

  .item_subtitle {
    font-size: 1em;
  }

  .slider_button,
  .btn-1,
  .btn-2 {
    padding: 10px 20px;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .item_title {
    font-size: 2em;
  }

  .item_description {
    font-size: 1.1em;
  }

  .item_subtitle {
    font-size: 0.9em;
  }

  .slider_button,
  .btn-1,
  .btn-2 {
    padding: 8px 16px;
    font-size: 0.8em;
  }
}
