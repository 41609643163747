/* General Styles */
.ftco-footer {
  padding: 4em 0;
  background: #2c2c2c;
  color: #fff;
  position: relative;
}

.ftco-footer .ftco-heading-2 {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 30px;
}

.ftco-footer a {
  color: #fff;
  transition: 0.3s;
  text-decoration: none;
}

.ftco-footer a:hover {
  color: #f8a035;
}

/* Mailto Link Specific Styles */
.ftco-footer a[href^="mailto"] {
  font-weight: bold;
  color: #f8a035; /* Custom color for mailto links */
}

.ftco-footer a[href^="mailto"]:hover {
  color: #e0952b;
  text-decoration: underline; /* Underline the email link when hovered */
}

.block-23 ul li a[href^="mailto"] .text {
  color: #f8a035; /* Color for email text */
  font-weight: 500; /* Slightly bolder font for mailto text */
}

/* Adjust spacing between address and mailto link */
.block-23 ul li:not(:last-child) {
  margin-bottom: 10px; /* Add margin between each list item */
}

.block-23 ul li a[href^="mailto"] {
  margin-top: 15px; /* Add more space above the mailto link */
  display: inline-block;
}

/* Contact Details */
.block-23 ul {
  padding: 0;
  margin: 0;
}

.block-23 ul li {
  list-style: none;
  margin-bottom: 20px;
}

.block-23 ul li .icon {
  margin-right: 15px;
  font-size: 20px;
  color: #f8a035;
}

.block-23 ul li .text {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
}

/* Links Section */
.ftco-footer-widget ul {
  padding: 0;
  margin: 0;
}

.ftco-footer-widget ul li {
  list-style: none;
  margin-bottom: 10px;
}

.ftco-footer-widget ul li a {
  font-size: 16px;
}

.ftco-footer-widget ul li a:hover {
  color: #f8a035;
}

/* Subscribe Section */
.subscribe-form .form-group {
  position: relative;
}

.subscribe-form .form-group input[type="text"] {
  border: 0;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 14px;
  width: 100%;
  background: #fff;
  color: #333;
  text-align: center;
}

.subscribe-form .form-group input[type="submit"] {
  border: 0;
  border-radius: 30px;
  padding: 10px 20px;
  background: #f8a035;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.subscribe-form .form-group input[type="submit"]:hover {
  background: #e0952b;
}

/* Social Media Icons */
.ftco-footer-social {
  padding: 0;
}

.ftco-footer-social li {
  display: inline-block;
  margin-right: 10px;
}

.ftco-footer-social li a {
  font-size: 20px;
  color: #fff;
  transition: all 0.3s ease;
}

.ftco-footer-social li a:hover {
  color: #f8a035;
}

.ftco-animate {
  animation: fadeInUp 1s;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/* Footer Bottom */
.ftco-footer .footer-bottom {
  margin-top: 20px;
  padding-top: 20px;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
  color: #fff;
  position: relative;
  bottom: 0;
  width: 100%;
}

.ftco-footer .footer-bottom p {
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
}

.ftco-footer .footer-bottom p a {
  color: #f8a035;
  text-decoration: none;
}

.ftco-footer .footer-bottom p a:hover {
  color: #e0952b;
}

.ftco-footer .footer-bottom .icon-heart {
  color: red;
}

/* Tablet responsiveness (for screens up to 768px wide) */
@media (max-width: 768px) {
  .ftco-footer {
    padding: 3em 1em;
  }
  .block-23 ul li .text {
    font-size: 14px;
  }
  .ftco-footer .footer-bottom p {
    font-size: 12px;
  }
  .ftco-footer .ftco-heading-2 {
    font-size: 18px;
  }
  .ftco-footer-widget ul li a {
    font-size: 14px;
  }
}

/* Mobile responsiveness (for screens up to 576px wide) */
@media (max-width: 576px) {
  .ftco-footer {
    padding: 2em 0.5em;
  }
  .block-23 ul li a[href^="mailto"] .text {
    font-size: 12px; /* Smaller text for email link */
  }
  .block-23 ul li .text {
    font-size: 12px;
  }
  .ftco-footer-widget ul li a {
    font-size: 12px;
  }
  .ftco-footer .footer-bottom p {
    font-size: 12px;
  }
}

/* Extra small devices (phones, 480px and down) */
@media (max-width: 480px) {
  .ftco-footer {
    padding: 2em 0.2em;
  }
  .ftco-footer .ftco-heading-2 {
    font-size: 16px;
  }
  .block-23 ul li a[href^="mailto"] .text {
    font-size: 10px; /* Extra small text for email link */
  }
  .block-23 ul li .text {
    font-size: 10px;
  }
  .ftco-footer-widget ul li a {
    font-size: 10px;
  }
  .ftco-footer .footer-bottom p {
    font-size: 10px;
  }
  .subscribe-form .form-group input[type="text"],
  .subscribe-form .form-group input[type="submit"] {
    font-size: 12px;
  }
  .ftco-footer-social li a {
    font-size: 18px;
  }
  .ftco-footer .footer-bottom p {
    font-size: 10px;
  }
  .ftco-footer .footer-bottom p a {
    font-size: 10px;
  }
}
