.tutor-search {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tutor-search h2 {
  text-align: center;
  color: #333;
}

.tutor-search label {
  display: block;
  margin: 10px 0 5px;
  font-weight: bold;
}

.tutor-search select,
.tutor-search button {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.tutor-search button {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.tutor-search button:hover {
  background-color: #45a049;
}

.message {
  color: red;
  font-weight: bold;
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

ul li p {
  margin: 5px 0;
}
