/* General Info Section Styles */
.info_section {
    background-color: #f6f3f3;
    color: #fefdfc;
    font-family: "Lato", sans-serif;
    padding: 50px 0;
  }
  
  /* Headings Styling */
  .info_section h5 {
    margin: 20px 0 25px 0;
    font-weight: 600;
    text-transform: uppercase;
    color: #ffff00;
    letter-spacing: 2px;
  }
  
  /* Form Heading */
  .info_section .form_heading {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #fefdfc;
  }
  
  /* Logo and Branding */
  .info_section .info_logo a {
    display: flex;
    align-items: flex-end;
    margin-bottom: 25px;
    text-decoration: none;
  }
  
  .info_section .info_logo a span {
    font-size: 24px;
    font-weight: 700;
    color: #fefdfc;
    margin-left: 10px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    transition: color 0.3s ease;
  }
  
  .info_section .info_logo a:hover span {
    color: #ffff00;
  }
  
  /* Form Styling */
  .info_section .info_form form {
    display: flex;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .info_section .info_form form input {
    flex: 1;
    height: 45px;
    border-radius: 30px;
    border: 2px solid #eeeeee;
    padding: 0 15px;
    font-size: 16px;
    outline: none;
    transition: border 0.3s ease;
  }
  
  .info_section .info_form form input:focus {
    border: 2px solid #ffff00;
  }
  
  .info_section .info_form form button {
    padding: 0 45px;
    height: 45px;
    margin-left: -100px;
    border: none;
    border-radius: 30px;
    background-color: #ffff00;
    color: #050505;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .info_section .info_form form button:hover {
    background-color: #e6e600;
    transform: translateX(10px);
  }
  
  /* Offer Section Styling */
  .offer_section .content-box {
    display: flex;
    align-items: center;
    background-color: #fefdfc;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .offer_section .content-box:hover {
    background-color: #8f9440;
    transform: translateY(-5px);
  }
  
  .offer_section .content-box .img-box {
    min-width: 125px;
    min-height: 115px;
    background-color: #eeeded;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }
  
  .offer_section .content-box:hover .img-box {
    background-color: #8f9440;
  }
  
  .offer_section .content-box .img-box svg {
    width: 55px;
    height: auto;
    fill: #050505;
    transition: fill 0.3s ease;
  }
  
  .offer_section .content-box:hover .img-box svg {
    fill: #fefdfc;
  }
  
  /* About Section Styling */
  .about_section .img-box img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .about_section .img-box img:hover {
    transform: scale(1.05);
  }
  
  /* Client Section */
  .client_section .client_container .img-box {
    width: 250px;
    border: 5px solid #7f7f31;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .client_section .client_container .img-box img {
    width: 100%;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  .client_section .client_container .img-box:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  }
  
  /* General Layout Enhancements */
  .section {
    padding: 60px 0;
  }
  
  .section h2 {
    font-size: 2.5rem;
    color: #ffff00;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
    letter-spacing: 2px;
  }
  
  .section p {
    font-size: 1.2rem;
    color: #fefdfc;
    text-align: center;
    line-height: 1.8;
    max-width: 800px;
    margin: 0 auto 30px auto;
  
  }
  .heading_container h2 {
    text-transform: uppercase;
    font-weight: bold;
    color: #0c0c0c;
    font-style: italic;
  }
  .section p {
    font-size: 2rem;
    color: #ffffff; /* Changed to pure white */
    text-align: center;
    line-height: 2;
    max-width: 900px;
    margin: 0 auto 30px auto;
  }
  /* Basic styling for the paragraph */
  p {
    font-weight: bold; /* Bold text */
    font-size: 1.2rem; /* Adjust size for readability */
    color: #ffffff; /* Pure white for the paragraph */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Stylish shadow for depth */
    animation: fadeInAndGrow 2s ease-out forwards; /* Apply the animation */
    opacity: 0; /* Initially hidden */
    transform: scale(0.8); /* Start with a smaller size */
  }
  
  /* Animation keyframes for stylish entry */
  @keyframes fadeInAndGrow {
    0% {
      opacity: 0; /* Start hidden */
      transform: scale(0.8); /* Start smaller */
    }
    100% {
      opacity: 1; /* Fully visible */
      transform: scale(1); /* Normal size */
    }
  }
  
  /* Mobile responsiveness (for screens up to 768px wide) */
  @media (max-width: 768px) {
    .slider_section {
      transform: translateY(0); /* Reset any translation for mobile */
      height: auto; /* Allow the section to adjust its height based on content */
      padding: 0 20px; /* Add padding for spacing */
      justify-content: center; /* Center align for mobile screens */
      padding-right: 0; /* Remove extra padding */
    }
  
    .slider_section .detail-box {
      transform: none; /* Reset any rotation or translation */
      animation: none; /* Disable sliding animation for mobile */
      text-align: center; /* Center text for better readability */
      margin-top: 20px; /* Add some top margin for spacing */
    }
  
    .slider_section .detail-box h1 {
      font-size: 1.5rem; /* Smaller font size for mobile */
    }
  
    .slider_section .detail-box span {
      font-size: 2.5rem; /* Reduce the main headline font size */
    }
  
    .slider_section .detail-box p {
      font-size: 1rem; /* Smaller font size for the paragraph */
      margin-top: 15px; /* Reduce the top margin */
      transform: none; /* Reset rotation */
    }
  
    .slider_section .detail-box .btn-box {
      margin-top: 30px; /* Adjust spacing between buttons and content */
    }
  
    .slider_section .detail-box .btn-box .btn-1,
    .slider_section .detail-box .btn-box .btn-2 {
      padding: 10px 30px; /* Adjust button padding for mobile */
      font-size: 0.9rem; /* Reduce button font size */
    }
  
    .slider_section ol.carousel-indicators {
      bottom: -40px; /* Adjust indicator position */
      justify-content: center; /* Center indicators on mobile */
    }
  
    .slider_section ol.carousel-indicators li {
      width: 15px; /* Smaller indicator size */
      height: 15px; /* Smaller indicator size */
      margin-right: 10px; /* Reduce space between indicators */
    }
  }
  
  /* Extra small devices (phones, 480px and down) */
  @media (max-width: 480px) {
    .slider_section .detail-box h1 {
      font-size: 1.2rem; /* Further reduce font size */
    }
  
    .slider_section .detail-box span {
      font-size: 2rem; /* Reduce the headline size for very small screens */
    }
  
    .slider_section .detail-box p {
      font-size: 0.9rem; /* Reduce paragraph size */
    }
  
    .slider_section .detail-box .btn-box .btn-1,
    .slider_section .detail-box .btn-box .btn-2 {
      padding: 8px 20px; /* Adjust button padding for smaller screens */
      font-size: 0.8rem; /* Smaller font size */
    }
  
    .slider_section ol.carousel-indicators li {
      width: 10px; /* Smaller indicator size */
      height: 10px; /* Smaller indicator size */
      margin-right: 5px; /* Reduce space between indicators */
    }
  }
  
  