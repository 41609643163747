/* src/components/Signup.css */
.signup-container {
    width: 90%;
    max-width: 350px; /* Further reduced max-width for compactness */
    margin: 0 auto;
    padding: 8px 15px; /* Reduced padding top and bottom */
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.signup-container h2 {
    text-align: center;
    margin-bottom: 8px; /* Reduced margin bottom */
    font-size: 1.3rem; /* Slightly smaller font size */
    color: #333;
}

.signup-container form {
    display: flex;
    flex-direction: column;
    gap: 6px; /* Reduced gap between elements */
}

.signup-container label {
    font-size: 0.8rem; /* Smaller font size */
    color: #555;
    font-weight: 600;
    margin-bottom: 2px; /* Reduced space below label */
}

.signup-container input,
.signup-container select,
.signup-container textarea {
    padding: 6px 8px; /* Reduced padding */
    font-size: 0.85rem; /* Smaller font size */
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.signup-container input[type="text"],
.signup-container input[type="email"],
.signup-container input[type="password"],
.signup-container textarea {
    height: 35px; /* Reduced height */
}

.signup-container select {
    height: 35px;
}

/* Button Styling */
.signup-container button {
    padding: 8px 12px; /* Reduced padding for button */
    background-color: #007bff;
    color: white;
    font-size: 0.85rem; /* Slightly smaller font size */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signup-container button:hover {
    background-color: #0056b3;
}

/* Optional Fields Styling */
.signup-container .optional-fields {
    background-color: #f1f1f1;
    padding: 8px; /* Reduced padding */
    border-radius: 4px;
    border: 1px solid #ddd;
}

.signup-container select[multiple] {
    height: 80px; /* Further reduced height */
}

/* Error Message Styling */
.signup-container .error-message {
    color: red;
    font-size: 0.85rem; /* Smaller font size */
    margin-top: 5px; /* Reduced margin */
    text-align: center;
}

/* Responsive Styling for smaller screens */
@media (max-width: 400px) {
    .signup-container {
        padding: 6px; /* Reduced padding top and bottom */
    }

    .signup-container h2 {
        font-size: 1.2rem;
    }

    .signup-container label {
        font-size: 0.75rem;
    }

    .signup-container input,
    .signup-container select,
    .signup-container button {
        font-size: 0.8rem;
        padding: 5px; /* Reduced padding for smaller screens */
    }

    .signup-container select[multiple] {
        height: 70px;
    }
}
