/* Base styling for the contact form */
.contact-form {
  max-width: 450px;
  width: 90%;
  margin: 1rem auto;
  padding: 1rem;
  background: linear-gradient(145deg, #ffffff, #f2f2f2);
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1), -3px -3px 12px rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-form:hover {
  transform: scale(1.02);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15), -5px -5px 15px rgba(255, 255, 255, 0.9);
}

.contact-form h2 {
  text-align: center;
  color: #6a1b9a;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.contact-form input,
.contact-form button {
  width: 100%;
  padding: 8px;
  margin: 6px 0;
  border-radius: 6px;
  border: none;
  font-size: 0.9rem;
  outline: none;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

/* Styling input fields */
.contact-form input {
  background-color: #f9f9f9;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.6);
  color: #333;
}

.contact-form input:focus {
  background-color: #eae7ff;
  border-left: 3px solid #6a1b9a;
  box-shadow: 0px 0px 5px rgba(106, 27, 154, 0.4);
}

/* Styling the submit button */
.contact-form button {
  background: linear-gradient(145deg, #6a1b9a, #4a148c);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease, transform 0.2s;
}

.contact-form button:hover {
  background-color: #5c1399;
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px rgba(106, 27, 154, 0.3);
}

/* Button click effect */
.contact-form button:active {
  background-color: #28a745; /* Green color when clicked */
  transform: scale(0.98);
}

/* Disabled button styling */
.contact-form button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Responsive styling */
@media (max-width: 768px) {
  .contact-form {
      padding: 0.8rem;
      max-width: 85%;
  }

  .contact-form h2 {
      font-size: 1.1rem;
  }

  .contact-form input,
  .contact-form button {
      font-size: 0.85rem;
      padding: 6px;
  }
}

@media (max-width: 480px) {
  .contact-form {
      padding: 0.7rem;
      max-width: 100%;
  }

  .contact-form h2 {
      font-size: 1rem;
  }

  .contact-form input,
  .contact-form button {
      font-size: 0.8rem;
      padding: 5px;
  }
}
