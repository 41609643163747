/* Container for the entire dashboard */
.dashboard-container {
    background-color: #f4f4f4;  /* Light background color */
    padding: 20px;              /* Add padding around the content */
    border-radius: 8px;         /* Rounded corners for the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    max-width: 1200px;          /* Limit the max width */
    margin: 20px auto;          /* Center the container */
    font-family: Arial, sans-serif;
  }
  
  /* Title of the dashboard */
  .dashboard-container h1 {
    color: #2c3e50;           /* Dark color for the title */
    font-size: 2rem;           /* Large text for the title */
    text-align: center;        /* Center the title */
    margin-bottom: 20px;       /* Space below the title */
  }
  
  /* Description paragraph style */
  .dashboard-container p {
    color: #7f8c8d;           /* Lighter color for text */
    font-size: 1.2rem;         /* Slightly larger text for readability */
    text-align: center;        /* Center the text */
    margin-bottom: 40px;       /* Space below the paragraph */
  }
  
  /* Buttons for actions (e.g., "Manage Sessions", "View Requests") */
  .dashboard-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #3498db; /* Blue color */
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 6px;
    margin: 10px 5px;
    font-size: 1rem;
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
  .dashboard-button:hover {
    background-color: #2980b9; /* Darker blue on hover */
  }
  
  /* Layout for dashboard sections (if you add more sections later) */
  .dashboard-sections {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid layout */
    gap: 20px;  /* Space between the grid items */
    margin-top: 20px;
  }
  
  .dashboard-sections div {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for sections */
    text-align: center;
  }
  
  .dashboard-sections div h3 {
    color: #2c3e50;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .dashboard-sections div p {
    color: #7f8c8d;
    font-size: 1rem;
  }
  
  