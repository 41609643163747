.auth-links {
  text-align: center; /* Center the buttons */
  margin: 20px 0; /* Add some spacing around */
}

.auth-links a {
  text-decoration: none; /* Remove underline */
  padding: 10px 20px; /* Add padding to create button effect */
  margin: 0 10px; /* Space between buttons */
  font-size: 16px; /* Increase font size */
  font-weight: bold; /* Make text bold */
  color: white; /* Text color */
  background-color: #6a1b9a; /* Purple background */
  border-radius: 5px; /* Rounded corners */
  border: 2px solid #6a1b9a; /* Border same as background */
  transition: all 0.3s ease; /* Smooth hover transition */
}

.auth-links a:hover {
  background-color: white; /* Change background on hover */
  color: #6a1b9a; /* Change text color on hover */
  border: 2px solid #6a1b9a; /* Keep border */
}

.auth-links a:active {
  transform: scale(0.95); /* Slight shrink effect on click */
}
