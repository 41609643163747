/* Full-Screen Navbar */
.navbar {
  width: 100%;
  max-width: 100%; /* Remove max-width to allow full width */
  margin: 0; /* Remove centering margin */
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(90deg, #219cb2 0%, #a4750f 100%); /* Rich purple to blue gradient */
  padding: 10px 0; /* Reduced padding for a thinner look */
  position: fixed;
  top: 0;
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  justify-content: space-between; /* Space between logo and nav items */
  align-items: center;
}

/* Navbar Logo */
.navbar .navbar-brand img {
  height: 40px; /* Adjusted logo height for better visibility */
  max-width: 50px; /* Limit logo width */
  object-fit: contain;
  margin-left: 20px; /* Adjust left margin */
}

/* Navbar Links */
.navbar .navbar-nav .nav-link {
  padding: 8px 15px; /* Adjusted padding for thinner appearance */
  font-size: 16px; /* Slightly larger font size for readability */
  font-weight: 500; /* Reduced font weight for a sleeker look */
  color: #fdfdfd; /* Off-white for better contrast */
  letter-spacing: 1.2px; /* Slightly increased letter spacing */
  text-transform: uppercase;
  transition: 0.3s ease;
  position: relative;
}

/* Hover Animation for Links */
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #f39c12; /* Golden hover effect */
  transform: translateY(-2px); /* Slightly less pronounced lift */
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Nav Link Hover Underline Effect */
.navbar .navbar-nav .nav-link::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px; /* Adjusted position */
  left: 50%;
  background-color: #f39c12; /* Golden underline */
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: translateX(-50%);
}

.navbar .navbar-nav .nav-link:hover::before,
.navbar .navbar-nav .nav-link.active::before {
  visibility: visible;
  width: 100%;
}

/* Dropdown Styling */
.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107"; /* Font Awesome icon for dropdown */
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  vertical-align: middle;
  margin-left: 8px; /* Adjusted margin */
  color: #fdfdfd; /* Off-white */
  transition: transform 0.3s ease;
}

/* Dropdown Rotation on Hover */
.navbar .nav-item:hover .dropdown-toggle::after {
  transform: rotate(180deg);
}

/* Dropdown Menu */
@media (min-width: 1200px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    visibility: hidden;
    top: 100%;
    font-weight: 400;
    font-family: 'Fredoka', sans-serif;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
    border: 0;
    transition: 0.5s ease;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.95); /* Light background */
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
}

.dropdown .dropdown-menu a:hover {
  background: #f39c12; /* Golden background on hover */
  color: #1a1a40; /* Dark navy text color */
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Dropdown Visibility and Animation */
.navbar .nav-item:hover .dropdown-menu {
  transform: rotateX(0deg);
  visibility: visible;
  opacity: 1;
}

/* Fixed Navbar Styling */
.fixed-top.bg-white .navbar {
  background-color: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Search Modal Customization */
#searchModal .modal-content {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Contact Info Styling */
.contact-info {
  color: #000; /* Black color */
  font-size: 14px; /* Adjust the font size */
  margin-right: 20px; /* Space from the right */
  display: flex; /* Flexbox for alignment */
  align-items: center; /* Center the text vertically */
}

/* Adjust for Responsive Design */
@media (max-width: 768px) {
  .slider_section {
    padding: 0 10px; /* Less padding for mobile */
  }

  .slider_section .detail-box {
    text-align: center; /* Center text for better readability */
    margin-top: 15px; /* Add some top margin for spacing */
  }

  .slider_section .detail-box h1 {
    font-size: 1.3rem; /* Smaller font size for mobile */
  }

  .slider_section .detail-box span {
    font-size: 2.2rem; /* Reduce the main headline font size */
  }

  .slider_section .detail-box p {
    font-size: 0.9rem; /* Smaller font size for the paragraph */
    margin-top: 10px; /* Reduce the top margin */
  }

  .slider_section .detail-box .btn-box .btn-1,
  .slider_section .detail-box .btn-box .btn-2 {
    padding: 8px 20px; /* Adjust button padding for mobile */
    font-size: 0.8rem; /* Reduce button font size */
  }

  .slider_section ol.carousel-indicators {
    bottom: -30px; /* Adjust indicator position */
    justify-content: center; /* Center indicators on mobile */
  }

  /* Adjust contact info for mobile */
  .contact-info {
    font-size: 12px; /* Smaller font size for mobile */
    margin-right: 10px; /* Reduce right margin on mobile */
  }
}

/* Extra small devices (phones, 480px and down) */
@media (max-width: 480px) {
  .slider_section .detail-box h1 {
    font-size: 1rem; /* Further reduce font size */
  }

  .slider_section .detail-box span {
    font-size: 1.8rem; /* Reduce the headline size for very small screens */
  }

  .slider_section .detail-box p {
    font-size: 0.8rem; /* Reduce paragraph size */
  }

  .slider_section .detail-box .btn-box .btn-1,
  .slider_section .detail-box .btn-box .btn-2 {
    padding: 6px 15px; /* Adjust button padding for smaller screens */
    font-size: 0.7rem; /* Smaller font size */
  }

  .slider_section ol.carousel-indicators li {
    width: 8px; /* Smaller indicator size */
    height: 8px; /* Smaller indicator size */
    margin-right: 5px; /* Reduce space between indicators */
  }
}
